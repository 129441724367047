<template>
    <div>
        <Header :customstyle="{ background: '#0D1912' }">
            <template slot="title">公告详情</template>
        </Header>
        <div class="detail">
            <div class="content" v-if="showNews">
                <div class="title">{{title}}</div>
                <div class="describle" v-html="detailcontent"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {getNoticeDetail} from '@/request/api'
    import Header from '../../components/header.vue'

    export default {
        name: 'Anndetail',
        components: {Header},
        data() {
            return {
                detailID: 0,
                title: '',
                showNews: false,
                detailcontent: ''
            };
        },

        mounted() {

        },
        activated() {
            this.showNews =false;
            this.detailID = this.$route.query.id
            this.getDetail()
        },
        methods: {
            getDetail() {
                getNoticeDetail({id: this.detailID}).then((res) => {
                    this.title = res.data.title
                    this.showNews = true;
                    this.detailcontent = res.data.content
                })
            }
        },
    };
</script>

<style lang="scss" scoped>
    .detail {
        padding: 10px 32px;
        background: #0D1912;
        min-height: 100vh;
        .content {
            padding: 24px;
            width: 100%;
            border-radius: 24px;
            background: rgba(32, 40, 35, 1);
            // color: rgba(139,166,149,1);
            //     font-size: 28px;
            //     font-weight: 400;
            .title {
                opacity: 1;
                color: rgba(214, 255, 227, 1);
                font-size: 32px;
                font-weight: 700;
                font-family: "PingFang SC";
                text-align: left;
                line-height: 44px;
            }
            .describle {
                color: rgba(139, 166, 149, 1);
                font-size: 28px;
                font-weight: 400;
                word-break: break-all;
            }
        }
    }
</style>